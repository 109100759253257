import React from "react";

// interfaces
import { IIconComponentProps } from "@/types/components/IconComponentProps";

function ListGearIcon(props: IIconComponentProps<HTMLSpanElement>) {
  return (
    <span {...props}>
      <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M25.6003 26.8994H6.40033C4.98593 26.8994 3.84033 25.7538 3.84033 24.3394V6.41938C3.84033 5.00498 4.98593 3.85938 6.40033 3.85938H25.6003C27.0147 3.85938 28.1603 5.00498 28.1603 6.41938V24.3394C28.1603 25.7538 27.0147 26.8994 25.6003 26.8994Z" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.84033 10.2611H28.1603" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.68688 7.04766C7.68304 7.04766 7.68048 7.05022 7.68048 7.05406C7.68048 7.0579 7.68304 7.06046 7.68688 7.06046C7.69072 7.06046 7.69328 7.0579 7.69328 7.05406C7.69328 7.05022 7.69072 7.04766 7.68688 7.04766" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.9418 7.04766C10.9379 7.04766 10.9354 7.05022 10.9354 7.05406C10.9354 7.0579 10.9379 7.06046 10.9418 7.06046C10.9456 7.06046 10.9482 7.0579 10.9482 7.05406C10.9482 7.05022 10.9456 7.04766 10.9418 7.04766" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.1927 7.04766C14.1889 7.04766 14.1863 7.05022 14.1863 7.05406C14.1863 7.0579 14.1889 7.06046 14.1927 7.06046C14.1966 7.06046 14.1991 7.0579 14.1991 7.05406C14.1991 7.05022 14.1953 7.04766 14.1927 7.04766" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.0004 14.7383V16.0183" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.0004 21.1406V22.4206" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.6723 16.6598L13.7757 17.2998" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.225 19.8591L19.3284 20.4991" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.6723 20.4991L13.7757 19.8591" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.225 17.2998L19.3284 16.6598" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.81 16.7693C18.8097 17.769 18.8097 19.3895 17.81 20.3891C16.8103 21.3888 15.1899 21.3888 14.1902 20.3891C13.1905 19.3895 13.1905 17.769 14.1902 16.7693C15.1899 15.7696 16.8103 15.7696 17.81 16.7693" stroke="#374151" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </span>
  );
}

export default ListGearIcon;
