import React from "react";


function ReportIcon() {

  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" stroke="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M25.3332 28V28C26.8065 28 27.9998 26.8067 27.9998 25.3333V17.3333C27.9998 16.5973 27.4025 16 26.6665 16H22.6665V25.3333C22.6665 26.8067 23.8598 28 25.3332 28Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.3335 10.6667H17.3335" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.3335 15.8268H17.3335" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.3335 21.4128H13.3335" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22.6667 15.9987V6.66536C22.6667 5.92936 22.0693 5.33203 21.3333 5.33203H5.33333C4.59733 5.33203 4 5.92936 4 6.66536V25.332C4 26.8054 5.19333 27.9987 6.66667 27.9987H25.3333" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default ReportIcon;
