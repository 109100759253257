"use client";


import React, { useState } from "react";
import { IoSettingsOutline } from "react-icons/io5";
import { usePathname } from "next/navigation";
import { FaChevronRight } from "react-icons/fa";
import { PiUsersThree } from "react-icons/pi";
import { FiLogOut } from "react-icons/fi";

// components
import Button from "@/shared/Button";
import Link from "@/components/Link";
import ReportIcon from "@/components/icons/ReportIcon";
import ListGearIcon from "@/components/icons/ListGearIcon";
import WindowGearIcon from "@/components/icons/WindowGearIcon";
import LoliveLogo from "@/components/icons/LoliveLogoBlackIcon";

// helpers
import account from "@/api/query/account";
import accountApi from "@/api/account";

// assets
import styles from "@/assets/styles/ops-dashboard/components/sidebar.module.scss";


const MENU_ITEMS = [
  {
    key: "reports",
    href: "/ops/dashboard",
    label: "Reports",
    icon: <ReportIcon/>,
    children: [
      {
        key: "reportsHirer",
        label: "Hirer",
        href: "/ops/dashboard/hirer",
      },
      {
        key: "reportsLandlord",
        label: "Landlord",
        href: "/ops/dashboard/landlord",
      },
    ],
  },
  {
    key: "operations",
    label: "Operations",
    icon: <ListGearIcon/>,
    href: "/ops/operations",
  },
  {
    key: "accounts",
    label: "Accounts",
    icon: <PiUsersThree/>,
    href: "/ops/accounts",
    children: [
      {
        key: "accountsHirer",
        label: "Hirer",
        href: "/ops/accounts/hirers",
      },
      {
        key: "accountsLandlord",
        label: "Landlord",
        href: "/ops/accounts/landlords",
      },
    ],
  },
  {
    key: "adminActions",
    label: "Admin actions",
    icon: <WindowGearIcon/>,
    href: "/ops/admin-actions",
  },
  {
    key: "settings",
    label: "Settings",
    icon: <IoSettingsOutline/>,
    href: "/ops/settings",
  }
];

function Sidebar() {
  const [activeItemKey, setActiveItemKey] = useState<string | null>(
    () => MENU_ITEMS.find(item => !!item.children)?.key || null
  );

  const pathname = usePathname();
  const { data } = account.useIsAuthenticated();

  // renders
  const subMenuItems = activeItemKey
    ? MENU_ITEMS.find((item) => item.key === activeItemKey && pathname.includes(item.href))?.children || []
    : [];

  // render
  return (
    <aside className={styles["locl-ops-sidebar"]} data-testid="locl-ops-sidebar">
      <ul className={styles["locl-ops-sidebar__inner"]}>
        <li className={`locl-ops-sidebar__item ${styles["locl-ops-sidebar__item_logo"]}`} data-testid="locl-ops-sidebar-logo">
          <LoliveLogo/>
        </li>
        {
          MENU_ITEMS.map((item) => (
            <li key={item.key} className={"locl-ops-sidebar__item"} data-testid="locl-ops-sidebar-menu-item">
              <Link
                href={!data?.accessToken ? "" : item.href}
                onClick={() => {
                  !!data?.accessToken && setActiveItemKey(item.children ? item.key : null);
                }}
                className={
                `${styles["locl-ops-sidebar__link"]} ${pathname.includes(item.href) ? styles["locl-ops-sidebar__link_active"] : ""}`
                }
                data-testid="locl-ops-sidebar-menu-item-link"
              >
                { item.icon }
                { item.label }
              </Link>
            </li>
          ))
        }
        {
          data?.accessToken && (
            <li
              className={`${styles["locl-ops-sidebar__action"]} ${styles["locl-ops-sidebar__link"]}`}
              onClick={() => accountApi.logOut("/ops")}
            >
              <FiLogOut/>
              Log Out
            </li>
          )
        }
      </ul>
      <div className={`${styles["locl-ops-sidebar__sub"]} ${subMenuItems.length ? styles["locl-ops-sidebar__sub_active"] : ""}`}>
        {
          !!subMenuItems.length && (<>
          
            <ul className={"locl-ops-sidebar__sub__inner"}>
              <Button
                icon={<FaChevronRight/>}
                type="secondary"
                success
                onClick={() => setActiveItemKey(null)}
                className={styles["locl-ops-sidebar__sub__button"]}
                data-testid="locl-ops-sidebar-menu-subitem-close"
              />
              {
                subMenuItems.map((child) => (
                  <li key={child.key} className={"locl-ops-sidebar__sub__item"} data-testid="locl-ops-sidebar-menu-subitem">
                    <Link
                      href={child.href}
                      className={
                      `${styles["locl-ops-sidebar__sub__link"]} ${pathname === child.href ? styles["locl-ops-sidebar__sub__link_active"] : ""}`
                      }
                    >
                      { child.label }
                    </Link>
                  </li>
                ))
              }
            </ul>
          </>)
        }
      </div>
    </aside>
  );
}

export default Sidebar;
